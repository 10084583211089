import React from 'react';
// import { Link } from "gatsby";
// import 'mdbreact/dist/css/mdb.css'
import {MDBAnimation} from 'mdbreact';
import {Helmet} from 'react-helmet';
import Service from './IndividualService';

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Services | Tintash</title>
    </Helmet>
    <div>
      <section className="main-service-heading" onTouchStart="">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <MDBAnimation type="fadeInDown" delay="0.5s">
              <div className="col-12 py-3">
                <h1 className="latoBlack text-white heading">Services</h1>
                <p className="latoRegular text-white description">
                  We offer expertise for end-to-end product design and development, with solutions
                  tailor-made to fit your product’s unique requirements
                </p>
              </div>
            </MDBAnimation>
          </div>
        </div>
      </section>
      <section className="main-service-filters mt-5 mb-4 d-none">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12">
              <div className="filter-box  filter-box--green px-2 pt-3 pb-4" data-box-id="web-dev">
                <img
                  width={60}
                  height={60}
                  src={
                    require('../../assets/images/services/main-page/web-development.svg').default
                  }
                  alt="Web Dev"
                />
                <img
                  width={60}
                  height={60}
                  src={
                    require('../../assets/images/services/main-page/web-development-hover.svg')
                      .default
                  }
                  alt="Web Dev"
                />
                <div className="title text-center mt-2 pb-4 mw-100">
                  Web
                  <br />
                  Development
                </div>
                <div className="arrow ">
                  <img
                    src={require('../../assets/images/services/main-page/gray.svg').default}
                    alt=""
                  />
                  <img
                    src={require('../../assets/images/services/main-page/green.svg').default}
                    alt=""
                  />
                </div>
              </div>
              <div
                className="filter-box filter-box--orange px-2 pt-3 pb-4"
                data-box-id="mobile-dev"
              >
                <img
                  width={60}
                  height={60}
                  src={
                    require('../../assets/images/services/main-page/mobile-development.svg').default
                  }
                  alt="Mobile Dev"
                />
                <img
                  width={60}
                  height={60}
                  src={
                    require('../../assets/images/services/main-page/mobile-development-hover.svg')
                      .default
                  }
                  alt="Mobile Dev"
                />
                <div className="title text-center mt-2 pb-4 mw-100">Mobile App Development</div>
                <div className="arrow">
                  <img
                    src={require('../../assets/images/services/main-page/gray.svg').default}
                    alt=""
                  />
                  <img
                    src={require('../../assets/images/services/main-page/orange.svg').default}
                    alt=""
                  />
                </div>
              </div>
              <div className="filter-box filter-box--yellow px-2 pt-3 pb-4" data-box-id="iot">
                <img
                  width={60}
                  height={60}
                  src={require('../../assets/images/services/main-page/iot.svg').default}
                  alt="iot"
                />
                <img
                  width={60}
                  height={60}
                  src={require('../../assets/images/services/main-page/iot-hover.svg').default}
                  alt="iot"
                />
                <div className="title text-center mt-2 pb-4 mw-100">
                  IoT
                  <br />
                  Development
                </div>
                <div className="arrow">
                  <img
                    src={require('../../assets/images/services/main-page/gray.svg').default}
                    alt=""
                  />
                  <img
                    src={require('../../assets/images/services/main-page/yellow.svg').default}
                    alt=""
                  />
                </div>
              </div>
              <div className="filter-box filter-box--orange px-2 pt-3 pb-4" data-box-id="ui-ux">
                <img
                  width={60}
                  height={60}
                  src={require('../../assets/images/services/main-page/ui-ux.svg').default}
                  alt="ui-ux"
                />
                <img
                  width={60}
                  height={60}
                  src={require('../../assets/images/services/main-page/ui-ux-hover.svg').default}
                  alt="ui-ux"
                />
                <div className="title text-center mt-2 pb-4 mw-100">UI/UX</div>
                <div className="arrow">
                  <img
                    src={require('../../assets/images/services/main-page/gray.svg').default}
                    alt=""
                  />
                  <img
                    src={require('../../assets/images/services/main-page/orange.svg').default}
                    alt=""
                  />
                </div>
              </div>
              <div className="filter-box filter-box--magenta px-2 pt-3 pb-4" data-box-id="unity">
                <img
                  width={60}
                  height={60}
                  src={
                    require('../../assets/images/services/main-page/unity-game-development.svg')
                      .default
                  }
                  alt="Mobile Dev"
                />
                <img
                  width={60}
                  height={60}
                  src={
                    require('../../assets/images/services/main-page/unity-game-development-hover.svg')
                      .default
                  }
                  alt="unity"
                />
                <div className="title text-center mt-2 pb-4 mw-100">
                  Game
                  <br />
                  Development
                </div>
                <div className="arrow">
                  <img
                    src={require('../../assets/images/services/main-page/gray.svg').default}
                    alt=""
                  />
                  <img
                    src={require('../../assets/images/services/main-page/magenta.svg').default}
                    alt=""
                  />
                </div>
              </div>
              <div className="filter-box filter-box--green px-2 pt-3 pb-4" data-box-id="2d-3d">
                <img
                  width={60}
                  height={60}
                  src={require('../../assets/images/services/main-page/2d-3d-game-art.svg').default}
                  alt="2d-3d"
                />
                <img
                  width={60}
                  height={60}
                  src={
                    require('../../assets/images/services/main-page/2d-3d-game-art-hover.svg')
                      .default
                  }
                  alt="2d-3d"
                />
                <div className="title text-center mt-2 pb-4 mw-100">
                  2D-3D
                  <br />
                  Game Art
                </div>
                <div className="arrow">
                  <img
                    src={require('../../assets/images/services/main-page/gray.svg').default}
                    alt=""
                  />
                  <img
                    src={require('../../assets/images/services/main-page/green.svg').default}
                    alt=""
                  />
                </div>
              </div>
              <div
                className="filter-box filter-box--magenta px-2 pt-3 pb-4"
                data-box-id="quality-assurance"
              >
                <img
                  width={60}
                  height={60}
                  src={
                    require('../../assets/images/services/main-page/quality-assurance.svg').default
                  }
                  alt="qa"
                />
                <img
                  width={60}
                  height={60}
                  src={
                    require('../../assets/images/services/main-page/quality-assurance-hover.svg')
                      .default
                  }
                  alt="qa"
                />
                <div className="title text-center mt-2 pb-4 mw-100">
                  Quality
                  <br />
                  Assurance
                </div>
                <div className="arrow">
                  <img
                    src={require('../../assets/images/services/main-page/gray.svg').default}
                    alt=""
                  />
                  <img
                    src={require('../../assets/images/services/main-page/magenta.svg').default}
                    alt=""
                  />
                </div>
              </div>
              <div
                className="filter-box filter-box--yellow px-2 pt-3 pb-4"
                data-box-id="project-management"
              >
                <img
                  width={55}
                  height={55}
                  src={
                    require('../../assets/images/services/main-page/research-development.svg')
                      .default
                  }
                  alt="r&d"
                />
                <img
                  width={55}
                  height={55}
                  src={
                    require('../../assets/images/services/main-page/research-development-hover.svg')
                      .default
                  }
                  alt="r&d"
                />
                <div className="title text-center mt-2 py-4 mw-100">
                  AI
                  <span className="latoBold"> &amp;</span>
                  ML
                </div>
                <div className="arrow">
                  <img
                    src={require('../../assets/images/services/main-page/gray.svg').default}
                    alt=""
                  />
                  <img
                    src={require('../../assets/images/services/main-page/green.svg').default}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-service-content mb-5 mt-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Service
                mainId="web-dev"
                mainClass="content-box content-box--green p-5"
                delay="0.5s"
                duration="1s"
                title="Web Development"
                description="We work with the latest tech stacks to engineer front-end and back-end solutions, and do everything from single page progressive web applications in React and Vue, to multi-tier services in Python, node.js and Go deployed on AWS and Google App Engine."
                link="/services/web-development"
                image_hover="web-development-hover.svg"
                image="web-development.svg"
                alt="web-development"
              />
              <Service
                mainId="mobile-dev"
                mainClass="content-box content-box--orange p-5"
                delay="0s"
                duration="1s"
                title="Mobile App Development"
                description="We specialise in native and cross-platform consumer apps, creating exceptional digital experience at every touch point. We have crafted applications ranging from immersive AR experiences to complex social platforms and healthcare solutions."
                link="/services/app-development"
                image_hover="mobile-development-hover.svg"
                image="mobile-development.svg"
                alt="mobile-development"
              />
              <Service
                delay="0s"
                mainId="iot"
                mainClass="content-box content-box--yellow p-5"
                duration="1s"
                title="IoT Development"
                description="We help our customers design innovative IoT products to enable seamless and smooth experiences. Our teams are well-versed at all levels of the product development cycle - from board design to UX / UI to app development and system integration."
                link="/services/iot"
                image_hover="iot-hover.svg"
                image="iot.svg"
                alt="iot"
              />
              <Service
                delay="0s"
                mainId="ui-ux"
                mainClass="content-box content-box--orange p-5"
                duration="1s"
                title="UI/UX"
                description="We combine product innovation with optimum usability. Through our iterative design process and thorough prototyping, we create an engaging and human-centric design that is scalable and a joy to use. Our team stays involved from the discovery phase to the final milestones to ensure that no part of your vision is lost in translation."
                link="/services/ui-ux"
                image_hover="ui-ux-hover.svg"
                image="ui-ux.svg"
                alt="ui-ux"
              />
              <Service
                delay="0s"
                duration="1s"
                mainId="unity"
                mainClass="content-box content-box--magenta p-5"
                title="Game Development"
                description="We specialise in game design, production and development of 2D and 3D games for mobile and web platforms, providing end to end solutions including rapid prototyping, polished execution of gameplay along with updates and bug-fixing support after launch. Our team have expert command over popular game engines like Unity, Unreal Engine, cocos2d and others."
                link="/services/game-development"
                image_hover="unity-game-development-hover.svg"
                image="unity-game-development.svg"
                alt="unity-game-development"
              />
              <Service
                delay="0s"
                mainId="2d-3d"
                mainClass="content-box content-box--green p-5"
                duration="1s"
                title="2D-3D Game Art"
                description="We create visually engaging 2D and 3D game art for mobile, PCs and AR/VR. While paying close attention to detail, our technical artists collaborate directly with programmers and other artists to assist them in asset production and integration."
                link="/services/2d-3d-art"
                image_hover="2d-3d-game-art-hover.svg"
                image="2d-3d-game-art.svg"
                alt="2D-3D-game-art"
              />
              <Service
                delay="0.s"
                duration="1s"
                mainId="quality-assurance"
                mainClass="content-box content-box--magenta p-5"
                title="Quality Assurance"
                description=" We help ensure premium product quality by offering a comprehensive QA process which encompasses all stages of development. Our services consist of a combination of automated and manual testing, making sure your product follows up-to-date quality assurance standards."
                link="/services/quality-assurance"
                image_hover="quality-assurance-hover.svg"
                image="quality-assurance.svg"
                alt="quality-assurance"
              />
              <Service
                delay="0.s"
                mainId="project-management"
                mainClass="content-box content-box--green p-5"
                duration="1s"
                title="Artificial Intelligence & Machine Learning"
                description="We provide services to augment your existing platforms and solutions with the power of computer vision, data visualizations, predictive analysis and more."
                link="/services/ai-ml"
                image_hover="research-development-hover.svg"
                image="research-development.svg"
                alt="research-development"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </>
);
