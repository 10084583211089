import React from 'react';
import {Link} from 'gatsby';
import {MDBAnimation} from 'mdbreact';
import {navigate} from 'gatsby'; // import navigate from gatsby

export default props => (
  <>
    <MDBAnimation type="fadeInUp" delay={props.delay} duration={props.duration} reveal>
      <div id={props.mainId} className={props.mainClass} onClick={() => navigate(props.link)}>
        <div className="row">
          <div className="col-lg-2">
            <img
              width={100}
              height={100}
              src={require(`../../../assets/images/services/main-page/${props.image}`).default}
              alt={props.alt}
            />
            <img
              width={100}
              height={100}
              src={
                require(`../../../assets/images/services/main-page/${props.image_hover}`).default
              }
              alt={props.alt}
            />
          </div>
          <div className="col-lg-10">
            <h1 className="text-left latoBlack title">{props.title}</h1>
            <p className="text-left latoRegular description">{props.description}</p>
            <div className="float-right">
              <Link
                to={props.link}
                className="link btn btn-sm btn-outline-primary service-btns px-2"
              >
                <span>Learn More</span>
                &nbsp; &nbsp;
                <i className="fa fa-arrow-right" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </MDBAnimation>
  </>
);
